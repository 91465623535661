var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "new-comment-container" },
    [
      _c("SearchForm", {
        attrs: {
          "label-width": 60,
          query: _vm.query,
          "search-column": _vm.searchColumn,
        },
        on: { getTableData: _vm.getTableData, resetForm: _vm.resetForm },
      }),
      _c("TablePaging", {
        attrs: {
          "button-list": _vm.buttonList,
          "column-list": _vm.columnList,
          loading: _vm.loading,
          "max-height": _vm.maxHeight,
          "page-info": _vm.pageInfo,
          selection: true,
          "table-data": _vm.list,
        },
        on: {
          handleAdopt: _vm.handleAdopt,
          handleCommentEdit: _vm.handleCommentEdit,
          handleCurrentChange: _vm.handleCurrentChange,
          handleFail: _vm.handleFail,
          handleOperationRecord: _vm.handleOperationRecord,
          handlePicDetails: _vm.handlePicDetails,
          handleQuickComments: _vm.handleQuickComments,
          handleSelectionChange: _vm.handleSelectionChange,
          handleTopAdopt: _vm.handleTopAdopt,
          handleTopFail: _vm.handleTopFail,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("div", { staticClass: "new-comment-user" }, [
                  _c("div", { staticClass: "new-comment-header" }, [
                    _c(
                      "div",
                      { staticClass: "new-comment-wrapper" },
                      [
                        _c("el-image", {
                          staticClass: "new-comment-head",
                          attrs: { src: row.user.head },
                        }),
                        row.user.cert_pic_url
                          ? _c("el-image", {
                              staticClass: "new-comment-cert",
                              attrs: { src: row.user.cert_pic_url },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "new-comment-info" }, [
                      row.delete == 2
                        ? _c("span", { staticClass: "new-comment-delete" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.translateTitle("用户已删除")) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          staticClass: "new-comment-name",
                          on: {
                            click: function ($event) {
                              return _vm.handleUserInfo(row.user_id)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(row.user.name) + " ")]
                      ),
                      row.user.group_ico_src
                        ? _c("img", {
                            staticClass: "new-comment-level",
                            attrs: { alt: "", src: row.user.group_ico_src },
                          })
                        : _vm._e(),
                      row.user.medal_pic_url
                        ? _c("img", {
                            staticClass: "new-comment-level",
                            attrs: { alt: "", src: row.user.medal_pic_url },
                          })
                        : _vm._e(),
                      _c("span", { staticClass: "new-comment-time" }, [
                        _vm._v(
                          " " +
                            _vm._s(row.pubdate_str) +
                            " (" +
                            _vm._s(row.whole_create_time_str) +
                            ") " +
                            _vm._s(_vm.translateTitle("回复了")) +
                            " "
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "new-comment-name",
                            on: {
                              click: function ($event) {
                                return _vm.handleUserInfo(row.to_user_id)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(row.to_user_name) + " ")]
                        ),
                      ]),
                      row.extra.role_banned === -1
                        ? _c(
                            "span",
                            {
                              staticClass: "new-comment-banner",
                              on: {
                                click: function ($event) {
                                  _vm.handleMute(
                                    row,
                                    null,
                                    _vm.translateTitle("禁言")
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.translateTitle("禁言")) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      row.extra.role_banned === 1
                        ? _c(
                            "span",
                            {
                              staticClass: "new-comment-cancel-banner",
                              on: {
                                click: function ($event) {
                                  _vm.handleCancelMute(
                                    row,
                                    null,
                                    _vm.translateTitle("取消禁言")
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.translateTitle("取消禁言")) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  row.reply_content
                    ? _c("div", [
                        _vm._v(
                          " " + _vm._s(_vm.translateTitle("引用")) + "： "
                        ),
                        row.reply_info && row.reply_time
                          ? _c("p", { staticClass: "new-comment-reply-info" }, [
                              _vm._v(
                                " " +
                                  _vm._s(row.reply_user_name) +
                                  " " +
                                  _vm._s(_vm.translateTitle("发表于")) +
                                  " " +
                                  _vm._s(row.reply_time) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _c("p", {
                          domProps: { innerHTML: _vm._s(row.reply_content) },
                        }),
                      ])
                    : _vm._e(),
                  row.show_content.id
                    ? _c("div", {
                        staticClass: "new-comment-show-content",
                        domProps: {
                          innerHTML: _vm._s(row.show_content.content),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleThemeDetail(row)
                          },
                        },
                      })
                    : _vm._e(),
                  _c("div", {
                    staticClass: "new-comment-content",
                    domProps: { innerHTML: _vm._s(row.content) },
                    on: {
                      click: function ($event) {
                        return _vm.handleThemeDetail(row)
                      },
                    },
                  }),
                  row.is_voice == 1
                    ? _c("audio", {
                        attrs: { controls: "", src: row.voice.url },
                      })
                    : _vm._e(),
                ]),
              ]
            },
          },
          {
            key: "other",
            fn: function (ref) {
              var row = ref.row
              return [
                row.audit == 2
                  ? _c("el-tag", { attrs: { type: "success" } }, [
                      _vm._v(" " + _vm._s(_vm.translateTitle("通过")) + " "),
                    ])
                  : _vm._e(),
                row.audit == 1
                  ? _c("el-tag", { attrs: { type: "warning" } }, [
                      _vm._v(" " + _vm._s(_vm.translateTitle("待审核")) + " "),
                    ])
                  : _vm._e(),
                row.audit == 3
                  ? _c("el-tag", { attrs: { type: "danger" } }, [
                      _vm._v(" " + _vm._s(_vm.translateTitle("未通过")) + " "),
                    ])
                  : _vm._e(),
                row.log
                  ? _c("div", { staticClass: "log" }, [
                      _c("span", [_vm._v(_vm._s(row.log.action))]),
                      _c("span", [_vm._v(_vm._s(row.log.create_time_str))]),
                      _c("span", [_vm._v(_vm._s(row.log.user.name))]),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("UserInfoDialog", {
        ref: "userInfoDialog",
        on: {
          getTableData: _vm.getTableData,
          handleCancelMute: _vm.handleCancelMute,
        },
      }),
      _c("AuditNotPassDialog", {
        ref: "auditNotPass",
        on: { handleAuditNotPass: _vm.handleAuditNotPass },
      }),
      _c("MuteDialog", {
        ref: "muteDialog",
        on: { getTableData: _vm.getTableData },
      }),
      _c("OperationRecord", {
        ref: "operationRecord",
        attrs: { themetype: _vm.query.themetype },
      }),
      _c("CommentEdit", {
        ref: "commentEdit",
        on: { getTableData: _vm.getTableData },
      }),
      _c("PicDetails", { ref: "picDetails" }),
      _c("ReplyDialog", {
        ref: "replyDialog",
        on: { getTableData: _vm.getTableData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }