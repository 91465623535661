<template>
  <div class="new-comment-container">
    <SearchForm
      :label-width="60"
      :query="query"
      :search-column="searchColumn"
      @getTableData="getTableData"
      @resetForm="resetForm"
    />
    <!-- 表格 -->
    <TablePaging
      :button-list="buttonList"
      :column-list="columnList"
      :loading="loading"
      :max-height="maxHeight"
      :page-info="pageInfo"
      :selection="true"
      :table-data="list"
      @handleAdopt="handleAdopt"
      @handleCommentEdit="handleCommentEdit"
      @handleCurrentChange="handleCurrentChange"
      @handleFail="handleFail"
      @handleOperationRecord="handleOperationRecord"
      @handlePicDetails="handlePicDetails"
      @handleQuickComments="handleQuickComments"
      @handleSelectionChange="handleSelectionChange"
      @handleTopAdopt="handleTopAdopt"
      @handleTopFail="handleTopFail"
    >
      <!-- 内容 -->
      <template #content="{ row }">
        <div class="new-comment-user">
          <div class="new-comment-header">
            <div class="new-comment-wrapper">
              <el-image class="new-comment-head" :src="row.user.head" />
              <el-image
                v-if="row.user.cert_pic_url"
                class="new-comment-cert"
                :src="row.user.cert_pic_url"
              />
            </div>
            <div class="new-comment-info">
              <span v-if="row.delete == 2" class="new-comment-delete">
                {{ translateTitle('用户已删除') }}
              </span>
              <span
                class="new-comment-name"
                @click="handleUserInfo(row.user_id)"
              >
                {{ row.user.name }}
              </span>
              <img
                v-if="row.user.group_ico_src"
                alt=""
                class="new-comment-level"
                :src="row.user.group_ico_src"
              />
              <img
                v-if="row.user.medal_pic_url"
                alt=""
                class="new-comment-level"
                :src="row.user.medal_pic_url"
              />
              <span class="new-comment-time">
                {{ row.pubdate_str }} ({{ row.whole_create_time_str }})
                {{ translateTitle('回复了') }}
                <span
                  class="new-comment-name"
                  @click="handleUserInfo(row.to_user_id)"
                >
                  {{ row.to_user_name }}
                </span>
              </span>
              <span
                v-if="row.extra.role_banned === -1"
                class="new-comment-banner"
                @click="handleMute(row, null, translateTitle('禁言'))"
              >
                {{ translateTitle('禁言') }}
              </span>
              <span
                v-if="row.extra.role_banned === 1"
                class="new-comment-cancel-banner"
                @click="handleCancelMute(row, null, translateTitle('取消禁言'))"
              >
                {{ translateTitle('取消禁言') }}
              </span>
            </div>
          </div>
          <div v-if="row.reply_content">
            {{ translateTitle('引用') }}：
            <p
              v-if="row.reply_info && row.reply_time"
              class="new-comment-reply-info"
            >
              {{ row.reply_user_name }} {{ translateTitle('发表于') }}
              {{ row.reply_time }}
            </p>
            <p v-html="row.reply_content"></p>
          </div>
          <div
            v-if="row.show_content.id"
            class="new-comment-show-content"
            @click="handleThemeDetail(row)"
            v-html="row.show_content.content"
          ></div>
          <div
            class="new-comment-content"
            @click="handleThemeDetail(row)"
            v-html="row.content"
          ></div>
          <audio v-if="row.is_voice == 1" controls :src="row.voice.url"></audio>
        </div>
      </template>
      <!-- 其他信息 -->
      <template #other="{ row }">
        <el-tag v-if="row.audit == 2" type="success">
          {{ translateTitle('通过') }}
        </el-tag>
        <el-tag v-if="row.audit == 1" type="warning">
          {{ translateTitle('待审核') }}
        </el-tag>
        <el-tag v-if="row.audit == 3" type="danger">
          {{ translateTitle('未通过') }}
        </el-tag>
        <div v-if="row.log" class="log">
          <span>{{ row.log.action }}</span>
          <span>{{ row.log.create_time_str }}</span>
          <span>{{ row.log.user.name }}</span>
        </div>
      </template>
    </TablePaging>
    <!-- 用户信息 -->
    <UserInfoDialog
      ref="userInfoDialog"
      @getTableData="getTableData"
      @handleCancelMute="handleCancelMute"
    />
    <!-- 审批不通过 -->
    <AuditNotPassDialog
      ref="auditNotPass"
      @handleAuditNotPass="handleAuditNotPass"
    />
    <!-- 禁言-对话框 -->
    <MuteDialog ref="muteDialog" @getTableData="getTableData" />
    <!-- 操作记录 -->
    <OperationRecord ref="operationRecord" :themetype="query.themetype" />
    <!-- 评论编辑 -->
    <CommentEdit ref="commentEdit" @getTableData="getTableData" />
    <!-- 图片详情 -->
    <PicDetails ref="picDetails" />
    <!-- 快捷评论 -->
    <ReplyDialog ref="replyDialog" @getTableData="getTableData" />
  </div>
</template>

<script>
  import config from './config'
  import SearchForm from '@/components/SearchForm'
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  import UserInfoDialog from '../../user/personList/components/userInfoDialog'
  import MuteDialog from '../../user/personList/components/muteDialog'
  import AuditNotPassDialog from '@/components/AuditNotPassDialog'
  import OperationRecord from '../components/operationRecord'
  import CommentEdit from '../components/commentEdit'
  import PicDetails from '../components/picDetails'
  import ReplyDialog from '../components/replyDialog'
  import {
    getAuditCommentList,
    updateAudit,
    getVestUserAllList,
  } from '@/api/audit'
  import { cancleUserMute, cancleBatchUserBanned } from '@/api/user'
  import { translateTitle } from '@/utils/i18n'
  import { randomNum } from '@/utils'
  export default {
    components: {
      SearchForm,
      TablePaging,
      AuditNotPassDialog,
      OperationRecord,
      CommentEdit,
      PicDetails,
      UserInfoDialog,
      MuteDialog,
      ReplyDialog,
    },
    mixins: [tableMixins],
    data() {
      return config
    },
    computed: {
      searchColumn() {
        return [
          {
            label: '状态',
            prop: 'state',
            type: 'select',
            clearable: false,
            selectList: this.stateList,
          },
          {
            label: '用户ID',
            prop: 'user_id',
          },
          {
            label: '关键字',
            prop: 'keywords',
          },
        ]
      },
      // 表格配置
      columnList() {
        return [
          { label: '内容', slotName: 'content', align: 'left' },
          { label: '其他信息', slotName: 'other', width: 140 },
          {
            label: '操作',
            isButton: true,
            btnList: [
              {
                label: '通过',
                type: 'primary',
                eventName: 'handleAdopt',
                showName: 'audit',
                showStatusList: [1, 3],
              },
              {
                label: '不通过',
                type: 'danger',
                eventName: 'handleFail',
                showName: 'audit',
                showStatusList: [1, 2],
              },
              {
                label: '快捷评论',
                type: 'primary',
                eventName: 'handleQuickComments',
              },
              // {
              //   label: '操作记录',
              //   type: 'primary',
              //   eventName: 'handleOperationRecord',
              // },
              // {
              //   label: '查看详情',
              //   type: 'primary',
              //   eventName: 'handleConfig',
              // },
              {
                label: '编辑',
                type: 'primary',
                eventName: 'handleCommentEdit',
                showName: 'ctype',
                showStatusList: ['show'],
              },
              {
                label: '图片详情',
                type: 'primary',
                eventName: 'handlePicDetails',
                showStatusList: this.setShow(),
              },
              {
                label: '删除',
                type: 'danger',
                eventName: 'handleConfig',
                isHide: this.$route.query.state === 3,
              },
            ],
          },
        ]
      },
    },
    watch: {
      'query.state': {
        handler(newVal) {
          this.pageInfo.page = 1
          localStorage.setItem('state3', newVal)
          this.getTableData()
        },
      },
      'query.themetype': {
        handler(newVal) {
          this.pageInfo.page = 1
          localStorage.setItem('themeType3', newVal)
          this.getTableData()
        },
      },
    },
    created() {
      if (this.$route.query.themeType) {
        this.query.themetype = Number(this.$route.query.themeType)
      } else {
        if (localStorage.getItem('themeType3')) {
          this.query.themetype = Number(localStorage.getItem('themeType3'))
        } else {
          this.query.themetype = 3
        }
      }

      if (this.$route.query.state) {
        this.query.state = Number(this.$route.query.state)
      } else {
        if (localStorage.getItem('state3')) {
          this.query.state = Number(localStorage.getItem('state3'))
        } else {
          this.query.state = 0
        }
      }

      if (this.$route.query.cid) this.query.cid = this.$route.query.cid

      if (this.$route.query.content_id)
        this.query.content_id = this.$route.query.content_id

      this.getTableData()
      this.getVestUserAllList()
    },
    methods: {
      translateTitle,
      setShow() {
        let arr = []
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i]) {
            if (this.list[i].pics_count > 0 && !this.list[i].video_url) {
              arr.push(i)
            }
          }
        }
        return arr
      },
      // 查询信息流列表
      getTableData(value) {
        let params = {
          page: this.pageInfo.page,
          step: this.pageInfo.step,
        }
        params = Object.assign(
          {},
          value === undefined ? this.query : value,
          params
        )
        this.loading = true
        getAuditCommentList(params).then((res) => {
          this.loading = false
          this.list = res.list || []
          this.pageInfo.count = res.total
        })
      },
      // 获取元宇宙用户
      async getVestUserAllList(params) {
        const res = await getVestUserAllList(params)
        if (res.success) this.vestUserList = randomNum(res.list, 1)
      },
      // 获取个人信息
      handleUserInfo(id) {
        this.$refs.userInfoDialog.handleOpen(id)
      },
      // 快捷评论
      handleQuickComments(val) {
        this.$refs.replyDialog.handleOpen(val, this.vestUserList, 'info')
      },
      // 通过
      handleAdopt(val) {
        const params = {
          content_id: val.content_id,
          comment_id: val.id,
          ctype: val.ctype,
          audit: 2,
          remark: '',
          is_push: false,
        }
        this.updateAudit(params)
      },
      // 通过不通过状态更新
      async updateAudit(params) {
        const res = await updateAudit(params)
        this.$message({
          type: res.success ? 'success' : 'error',
          message: res.success ? `更新状态成功` : `更新状态失败`,
        })
        if (res.success) this.getTableData()
      },
      // 批量通过
      handleTopAdopt() {
        if (this.selectionList.length === 0) {
          return this.$message.error('请至少选择一条数据进行操作')
        }
        const params = {
          content_id: this.selectionList.map((item) => item.content_id).join(),
          comment_id: this.selectionList.map((item) => item.id).join(),
          ctype: this.cTypeList.find(
            (item) => item.value == this.query.themetype
          ).type,
          audit: 2,
          remark: '',
          is_push: false,
        }
        this.updateAudit(params)
      },
      // 批量不通过弹框
      handleTopFail() {
        if (this.selectionList.length === 0) {
          return this.$message.error('请至少选择一条数据进行操作')
        }
        const val = {
          content_id: this.selectionList.map((item) => item.content_id).join(),
          id: this.selectionList.map((item) => item.id).join(),
          ctype: this.cTypeList.find(
            (item) => item.value == this.query.themetype
          ).type,
        }
        this.$refs.auditNotPass.handleOpen(val)
      },
      // 禁言/禁止私聊
      handleMute(row, index, buttonName) {
        this.$refs.muteDialog.handleOpen(buttonName, row)
      },
      // 取消禁言/恢复私聊
      handleCancelMute(row, index, buttonName) {
        const params = { id: row.user_id }
        this.$confirm(`此操作将${buttonName}当前这条数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            let res
            if (buttonName === '取消禁言') {
              res = await cancleUserMute(params)
            } else {
              res = await cancleBatchUserBanned(params)
            }
            this.$message({
              type: res.success ? 'success' : 'error',
              message: res.success ? `${buttonName}成功` : `${buttonName}失败`,
            })
            if (res.success) this.getTableData()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      },
      // 不通过按钮
      handleFail(val) {
        this.$refs.auditNotPass.handleOpen(val)
      },
      // 不通过弹窗
      handleAuditNotPass(val, query) {
        const params = {
          content_id: val.content_id,
          comment_id: val.id,
          ctype: val.ctype,
          audit: 3,
          remark: query.remark,
          is_push: query.is_push,
        }
        this.updateAudit(params)
      },
      // 操作记录
      handleOperationRecord(val) {
        this.$refs.operationRecord.handleOpen(val)
      },
      // 评论编辑
      handleCommentEdit(val) {
        this.$refs.commentEdit.handleOpen(val)
      },
      // 图片详情
      handlePicDetails(val) {
        this.$refs.picDetails.handleOpen(val)
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.new-comment';
  #{$base}-user {
    #{$base}-header {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      #{$base}-wrapper {
        position: relative;
        cursor: pointer;
        margin-right: 10px;
        #{$base}-head {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
        #{$base}-cert {
          width: 16px;
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
      #{$base}-info {
        font-size: 13px;
        #{$base}-name {
          color: $base-color-blue;
          cursor: pointer;
        }
        #{$base}-level {
          height: 16px;
          margin: 0 3px;
          vertical-align: sub;
        }
        #{$base}-time {
          color: $base-color-text-secondary;
        }
        #{$base}-banner {
          color: $base-color-red;
          cursor: pointer;
        }
        #{$base}-cancel-banner {
          color: $base-color-yellow;
          cursor: pointer;
        }
      }
      #{$base}-reply-info {
        color: $base-color-text-secondary;
      }
    }
    #{$base}-show-content {
      color: $base-color-green;
      cursor: pointer;
      font-size: 12px;
      line-height: 1;
    }
    #{$base}-content {
      text-align: left;
    }
  }
  .log {
    span {
      margin: 0 5px;
    }
  }
</style>
